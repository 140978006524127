<template>
  <div id="home">
    <van-nav-bar
        title="修改密码"
        :left-arrow="true"
        @click-left="goback('/ucenter')"
        fixed
    />
    <div class="login profile">
      <div class="r-s rs-login">
        <div class="u-login u-reg-form u-modify">
          <div class="u-pre"></div>
          <input type="number" v-model="regform.account" disabled placeholder="请输入您的手机号码">
          <input type="password" v-model="regform.password" placeholder="请输入新密码">
          <input type="password" v-model="regform.confirm_pass" placeholder="请输重复新密码">
          <div class="u-code">
            <input type="number" v-model="regform.code" placeholder="请输入短信验证码">
            <div class="u-c-djs" v-if="issend == false" @click.stop="getcode">获取验证码</div>
            <div class="u-c-djs" v-else>{{ afterseconds }}</div>
          </div>
          <van-button @click.stop="reg">保存</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget, httppost} from "@/libs/api";
import { useRouter } from 'vue-router'
import {Toast} from "vant";

export default {
  name: 'Home',
  setup() {
    const router = useRouter();
    const state = reactive({
      banner: require('@/assets/default/imgs/aboutus.jpg'),
      issend: false,
      afterseconds: '',
      regform:{
        account:'',
        password:'',
        confirm_pass:'',
        code:''
      },
    })
    const getinfo = async () => {
      let res = await httpget('/api/member/getinfo');
      if( res.code == 0 ){
        state.regform.account = res.data.account;
      } else {
        Toast.fail(res.msg);
      }
    }
    const reg = async () => {
      if( !state.regform.password ){
        Toast.fail('输入新密码');
        return false;
      }
      if( !state.regform.confirm_pass ){
        Toast.fail('输入重复新密码');
        return false;
      }
      if( state.regform.password != state.regform.confirm_pass  ){
        Toast.fail('密码不一致');
        return false;
      }
      if( !state.regform.code ){
        Toast.fail('输入验证码');
        return false;
      }
      let res = await httppost("/api/member/modify",state.regform);
      if( res.code == 0 ){
        Toast.success(res.msg);
        setTimeout(() => {
          router.push({path: '/ucenter'}).catch(err => {err});
        }, 1500);
      } else {
        Toast.fail(res.msg);
        return false;
      }
    }
    const getcode = async() =>{
      let res = await httppost('/api/member/sendcode',{mobile:state.regform.account});
      if( res.code == 0 ){
        state.issend = true;
        //倒计时
        djs(res.data);
      } else {
        Toast.fail(res.msg);
        return false;
      }
    }
    const djs = (seconds) =>{
      state.afterseconds = seconds+'秒后重新发送';
      var timer = setInterval(function(){
        seconds --;
        if( seconds > 0 ){
          state.afterseconds = seconds+'秒后重新发送';
        } else {
          clearInterval(timer)
          state.issend = false;
        }
      },1000);
    }
    onMounted(() => {
      getinfo();
    })
    return {
      ...toRefs(state),
      reg,
      getcode,
      getinfo,
      djs
    };
  },
}
</script>
